<template>
    <div class="order h100" style="background: #f9f9f9; overflow: auto">
        <div class="container h100" style="overflow: initial !important">
            <div class="main_title home_main_title">Order <a-button @click="back">Back</a-button></div>
            <div class="order_info">
                <div class="order_title">Submit Your Order</div>
                <div class="order_tips">Submit your order for review and we’ll confirm and answer any questions before moving forward.Name</div>
                <div class="order_price">
                    Total cost include installation: <strong class="price">${{$route.query.cost}}</strong>
                </div>
                <div class="order_form">
                    <a-spin :spinning="spinning">
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Name">
                            <a-input v-model:value="formData.name" placeholder="Name" />
                            </a-form-item>
                            <a-form-item label="Email">
                            <a-input v-model:value="formData.email" placeholder="Email" />
                            </a-form-item>
                            <a-form-item label="Phone number">
                            <a-input v-model:value="formData.phone" placeholder="Phone number" />
                            </a-form-item>
                            <a-form-item label="Home address">
                            <a-input v-model:value="formData.address" placeholder="Home address" />
                            </a-form-item>
                            <a-form-item label="Proposed installation date">
                            <a-date-picker valueFormat="YYYY-MM-DD" :showToday="false" v-model:value="formData.instation_date" style="width: 100%" placeholder="Proposed installation date" />
                            </a-form-item>
                            <a-form-item label="Already have a solar system">
                                <a-switch v-model:checked="formData.have_system" />
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
                                <a-button type="primary" block class="btn primary" @click="order">Place order</a-button>
                            </a-form-item>
                        </a-form>
                    </a-spin>
                </div>
            </div>
        </div>
        <a-modal v-model:visible="successStatus" :cancel-button-props="{style:{display:'none'}}" title="Success" @ok="onOk">
            <a-result
                status="success"
                title="Successfully"
                sub-title="Our staff will contact you as soon as possible"
            >
            </a-result>
        </a-modal>
    </div>
</template>
<script>
import commonMixins from '@/mixins/commonMixins'
import { createOrder } from '@/apis/home/order'
import storage from '@/utils/storage'
export default {
    name: 'Order',
    mixins: [commonMixins],
    data(){
        return {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
            formData: {
                name: '',
                phone: '',
                email: '',
                address: '',
                instation_date: '',
                have_system: false,
                source: 0
            },
            successStatus: false,
            spinning: false
        }
    },
    methods: {
        async order(){
            this.spinning = true
            const source = this.$route.query.source
            if(source == 1){
                const cartList = storage.getLocalData('cart_data')
                const products = cartList ? JSON.parse(cartList) : []
                this.formData.products = products
            }
            const params = Object.assign(this.formData, this.$route.query)
            const res = await createOrder(params)
            this.spinning = false
            if(res){
                storage.clear()
                this.successStatus = true
            }
        },
        onOk(){
            this.$router.push({path: '/'})
        }
    },
    created(){
        this.formData.cost = this.$route.bill
    }
}
</script>
<style lang="stylus" scoped>
.order_info
    width 600px
    margin auto
    padding 20px
    background #fff
    box-shadow 0 10px 10px 0 rgba(0,0,0,.05)  
    margin-top 30px
    border-radius 20px
    .order_title
        font-size 28px
    .order_tips
        font-size 14px
        color #abb1bf
    .order_price
        padding 10px 0
        text-align center
        margin-bottom 20px
        .price
            font-size 20px
            color #43af52
</style>